
import { Roles } from "@/api";
import { Role } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { PageTitle }
})
export default class UserRolesPage extends Vue {
  headersTitle = [{ icon: "mdi-account-lock", header: "Roles" }];
  buttonsArray = [
    {
      text: "Create",
      icon: "",
      color: "gray",
      action: "create"
    }
  ];
  roles: Array<Role> = [];
  headers = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "150px"
    }
  ];
  async created() {
    try {
      this.roles = await Roles.list();
    } catch (e) {
      await this.$error(e.errors);
    }
  }
  async onEdit(id: number) {
    await this.$router.push({
      name: "EditRole",
      params: { id: id.toString() }
    });
  }
  async onDelete(id: number) {
    const res = await this.$dialog.error({
      text: "Do you realy want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await Roles.remove(id.toString());
        const index = this.roles.findIndex((item: Role) => item.id === id);
        this.roles.splice(index, 1);
      } catch (e) {
        await this.$error(e.errors);
      }
    }
  }
  async onCreateRole() {
    await this.$router.push({ name: "CreateRole" });
  }
}
